import type { PulseExperiment } from './pulse-util.js';
import type { UserAbGroup } from '../../../../config/types/user.js';

import { fasten } from '../frontend-api.js';
import {
  configureExtendedConfig,
  createPulseObject,
  createPulseTarget,
  getDrEditionProps,
  getNewsMediaPulseTracker,
  getPulseMeta,
  prepReferringImpression,
} from './pulse-util.js';
import { isLocalhost, isReviewApp } from '../utils/is-localhost.js';
import { getTcfConfig } from '../sourcepoint.js';
import { generateMetrics } from '../../../../src/core/mandatory-integrations/pulse/utils/metrics.js';

export interface PulseConfigOptions {
  trackTimeSpent?: boolean;
  enableImpressions?: boolean;
  trackExperiments?: boolean;
  skipInitalPageView?: boolean;
  pageId?: string;
  abTest?: {
    name?: string;
    variant?: string;
    platform?: string;
    abTestValue: number | null;
    userABGroup?: UserAbGroup;
  };
  experiments?: {
    id: string;
    variant: string;
    platform?: string;
  }[];
  curate?: {
    variant: string;
    experiment?: {
      '@id': string;
      id: string;
      name: string;
      platform: string;
      variant: string;
    };
  };
}

export async function pulseConfig(
  publicationCode: string,
  options?: PulseConfigOptions,
) {
  // set up base object for meta data and plug-in access
  window.pulse('init', publicationCode, {
    useBeacon: true,
    useBeaconWhenAvailable: true,
    ...getTcfConfig(),
  });

  const FRONT_PAGE = 'Frontpage';
  const LISTING = 'Listing';
  const pageId = fasten.sectionName() || fasten.sectionPath();
  let pageType = LISTING;
  let isFront = false;
  if (pageId && pageId.toLowerCase() === 'forsiden') {
    pageType = FRONT_PAGE;
    isFront = true;
  }

  //check for additional tracking or meta values from options
  let shouldTrackImpressions = false;
  let shouldTrackExperiments = false;
  let shouldTrackTimeSpent = false;
  let shouldAddAbTestMeta = false;
  let skipInitalPageView = false;

  if (options) {
    await configureExtendedConfig(options);

    if (Object.prototype.hasOwnProperty.call(options, 'enableImpressions')) {
      shouldTrackImpressions = !!options.enableImpressions;
    }

    //currently limited to frontpage only
    if (isFront) {
      if (Object.prototype.hasOwnProperty.call(options, 'abTest')) {
        shouldAddAbTestMeta = true;
      }

      shouldTrackExperiments = true;
    }
    if (Object.prototype.hasOwnProperty.call(options, 'trackTimeSpent')) {
      shouldTrackTimeSpent = true;
    }
    if (
      Object.prototype.hasOwnProperty.call(options, 'skipInitalPageView') &&
      options.skipInitalPageView
    ) {
      skipInitalPageView = true;
    }
    if (
      Object.prototype.hasOwnProperty.call(options, 'trackExperiments') &&
      options.trackExperiments
    ) {
      shouldTrackExperiments = true;
    }
  }

  const host = window.location.host;
  if (isLocalhost()) {
    // send local test data to debug flow -> https://pulse.schibsted.io/debug
    window.pulse('update', {
      deployStage: 'dev',
      deployTag: 'fasten-dev',
    });
    // show errors directly
    document.cookie = '_pulse2debug=true';
  }
  if (isReviewApp()) {
    window.pulse('update', {
      deployStage: 'dev',
      deployTag: 'fasten-pr',
    });
    document.cookie = '_pulse2debug=true';
  }

  if (host.indexOf('alpha.') === 0) {
    window.pulse('update', {
      deployStage: 'dev',
      deployTag: 'fasten-alpha',
    });
    document.cookie = '_pulse2debug=true';
  }

  if (host.indexOf('beta.') === 0) {
    window.pulse('update', {
      deployStage: 'dev',
      deployTag: 'fasten-beta',
    });
    document.cookie = '_pulse2debug=true';
  }

  // curate personalization
  window.pulse('update', {
    object: {
      type: pageType,
      id: options?.pageId ? options.pageId : pageId,
    },
  });
  window.pulse('require', 'localHistory', { objectType: pageType });
  window.pulse('require', 'populateDefaultValues', {});
  // }
  if (shouldAddAbTestMeta) {
    // curate hacks as plugin'ish - adds 3 additional spt:custom fields
    window.pulse('require', 'helloAbTest', options?.abTest);
  }
  if (shouldTrackExperiments) {
    let { experiments = [] } = options || {};

    if (options?.curate?.experiment) {
      experiments.push(options.curate.experiment);
    }

    experiments = experiments
      .concat(window.PULSE_EXPERIMENTS || [])
      .map((experiment) => {
        if (!experiment.platform) {
          experiment.platform = publicationCode;
        }

        return experiment;
      });

    if (options) {
      options.experiments = experiments;
    }
    window.PULSE_EXPERIMENTS = experiments;

    const { variant, personalization } = window.CURATE_CONFIG || {};

    window.pulse('update', {
      experiments,
      metrics: generateMetrics(variant, personalization),
    });
  }

  const { trackViewListing, trackViewFrontpage } =
    await getNewsMediaPulseTracker();

  if (!skipInitalPageView) {
    const id = options?.pageId ? options.pageId : pageId || '';
    const name = document.title;

    if (isFront) {
      trackViewFrontpage({ object: { id, name } });
    } else {
      trackViewListing({ object: { id, name } });
    }
  }

  if (shouldTrackImpressions) {
    window.pulse('require', 'visibilityTracking', {
      publicationCode,
      pageId,
      pageType,
    });

    document.addEventListener(
      'click',
      async (event) => {
        const target = event.target as HTMLElement;
        const closestElement = target.closest('[data-pulse-entity-id]');
        if (closestElement) {
          await prepReferringImpression(closestElement as HTMLElement);
          window.trackClick(closestElement);
        }
      },
      true,
    );
  }

  if (shouldTrackTimeSpent) {
    window.pulse('require', 'engagementTime');
  }

  window.trackClick = function (element) {
    const pulseMeta = getPulseMeta(element);
    const target = createPulseTarget(pulseMeta, publicationCode);
    const object = createPulseObject(
      pulseMeta,
      publicationCode,
      pageId || 'pageId-unknown',
      pageType,
    );
    const device = fasten.siteVersion();

    const experiments = [
      ...(window.PULSE_EXPERIMENTS || []),
      ...(pulseMeta.experiments || []),
    ] as PulseExperiment[];

    updateExperimentsInLocalStorage(experiments);

    window.pulse(
      'track',
      'engagementEvent',
      {
        action: 'Click',
        object,
        target,
        experiments,
        ...getDrEditionProps(pulseMeta, device, 'Click'),
      },
      true,
    );
  };

  const updateOrigin = () => {
    try {
      localStorage.removeItem('previousArticle');
      localStorage.setItem(
        'previousPage',
        JSON.stringify({
          type: pageType,
          id: options?.pageId ? options.pageId : pageId,
          url: window.location.href,
          referrer: window.document.referrer,
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

  const updateExperimentsInLocalStorage = (experiments: PulseExperiment[]) => {
    const EXPERIMENTS = 'experiments';

    try {
      localStorage.setItem(EXPERIMENTS, JSON.stringify(experiments));
    } catch (err) {
      console.error('Unable to update experiments to the localStorage:', err);
    }
  };

  window.addEventListener('pageshow', updateOrigin);
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      updateOrigin();
    }
  });
  window.addEventListener('focus', updateOrigin);
}
