import type { UserConsents, UserConsent } from './privacy-settings-api.js';

import {
  fetchAuthenticatedUserConsents,
  fetchAnonymousUserConsents,
} from './privacy-settings-api.js';
import { frontendConfig } from '../../../src/core/system/config/frontend.server.js';
import { resolveOnce } from './utils/resolve-once.js';
import { getSessionData } from './schibsted-account/index.server.js';

let userConsents: UserConsents;

type ProcessingPurpose = string | RegExp;

export type UserConsentsSession = {
  getEnvironmentId: () => Promise<string | undefined | null>;
  getUserId: () => Promise<number | null>;
  getUserToken: () => Promise<string | null>;
};

const processingPurposeCondition = (
  processingPurpose: ProcessingPurpose,
  userConsent: UserConsent,
) =>
  processingPurpose instanceof RegExp
    ? processingPurpose.test(userConsent.purpose)
    : userConsent.purpose === processingPurpose;

const isConsent = (
  processingPurpose: ProcessingPurpose,
  dataCategory?: string,
): boolean => {
  return userConsents.some(
    (userConsent) =>
      processingPurposeCondition(processingPurpose, userConsent) &&
      (dataCategory ? userConsent.data.includes(dataCategory) : true),
  );
};

const fetchUserConsentsOnce = (userSession: UserConsentsSession) =>
  resolveOnce(async () => {
    const environmentId = await userSession.getEnvironmentId();
    const userId = await userSession.getUserId();
    const userToken = await userSession.getUserToken();

    const { publication, apiUrl } = frontendConfig.privacySettingsConfig;

    const isLoggedIn = userId && userToken;

    try {
      if (isLoggedIn) {
        ({ permissions: userConsents } = await fetchAuthenticatedUserConsents(
          userId,
          userToken,
          publication,
          apiUrl,
        ));
      } else if (environmentId) {
        ({ permissions: userConsents } = await fetchAnonymousUserConsents(
          environmentId,
          publication,
          apiUrl,
        ));
      } else {
        userConsents = [];
      }
    } catch (error) {
      userConsents = [];
    }
  });

const legacyUserSession: UserConsentsSession = {
  getEnvironmentId: () =>
    new Promise((resolve) => {
      window.pulse((sdk) => {
        sdk.getEnvironmentId().then(resolve);
      });
    }),
  getUserId: async () => {
    const sessionData = await getSessionData();

    if (!sessionData) {
      return null;
    }

    const {
      user: { userId },
    } = sessionData;

    return userId;
  },
  getUserToken: async () => {
    const sessionData = await getSessionData();

    if (!sessionData) {
      return null;
    }
    const {
      user: { userToken },
    } = sessionData;

    return userToken;
  },
};

export const isUserConsent = async (
  processingPurpose: ProcessingPurpose,
  dataCategory: string,
  userSession: UserConsentsSession = legacyUserSession,
): Promise<boolean> => {
  if (userConsents) {
    return isConsent(processingPurpose, dataCategory);
  }

  await fetchUserConsentsOnce(userSession)();

  return isConsent(processingPurpose, dataCategory);
};

export const isUserConsentAll = (): boolean =>
  Boolean(
    !window._tcf_?.getConsentedToAllSync ||
      window._tcf_?.getConsentedToAllSync(),
  );
